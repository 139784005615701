import { RouterModule, Routes } from '@angular/router'
import { NgModule } from '@angular/core'
import { AuthGuard } from './shared/auth.guard'
import { PageNotFoundComponent } from './page-redirect-error/page-not-found/page-not-found.component'

const routes: Routes = [
  {
    loadChildren: () =>
      import(`./help-contact/help-contact.module`).then(
        (m) => m.HelpContactModule
      ),
    path: `help-center`
  },
  {
    loadChildren: () =>
      import(`./profile/profile.module`).then((m) => m.ProfileModule),
    path: `account-management`
  },
  {
    loadChildren: () =>
      import(`./login/login.module`).then((m) => m.LoginPageModule),
    path: `login`
  },
  {
    loadChildren: () =>
      import(`./design-system/design-system.module`).then(
        (m) => m.DesignSystemPageModule
      ),
    path: `design-system`
  },
  {
    canActivate: [AuthGuard],
    path: `:role`,
    children: [
      {
        loadChildren: () =>
          import(`./charging-history/charging-history.module`).then(
            (m) => m.ChargingHistoryPageModule
          ),
        path: `charging-history`
      }
    ]
  },
  {
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(`./dashboard/dashboard.module`).then((m) => m.DashboardPageModule),
    path: `dashboard`
  },
  {
    canActivate: [AuthGuard],
    path: `utility-configuration`,
    loadChildren: () =>
      import(`./utilities-config/utilities-config.module`).then(
        (m) => m.UtilitiesConfigModule
      )
  },
  {
    canActivate: [AuthGuard],
    path: `system`,
    loadChildren: () =>
      import(`./system/system.module`).then((m) => m.SystemModule)
  },
  {
    canActivate: [AuthGuard],
    path: `security`,
    loadChildren: () =>
      import(`./security/security.module`).then((m) => m.SecurityModule)
  },
  {
    canActivate: [AuthGuard],
    path: `accounts`,
    loadChildren: () =>
      import(`./accounts/accounts.module`).then((m) => m.AccountsModule)
  },
  {
    canActivate: [AuthGuard],
    path: `error-page`,
    loadChildren: () =>
      import(`./page-redirect-error/page-redirect-error.module`).then(
        (m) => m.PageRedirectErrorModule
      )
  },
  {
    path: ``,
    pathMatch: `full`,
    redirectTo: `dashboard/home`
  },
  //Wildcard route for handling unknown paths
  { path: `**`, component: PageNotFoundComponent }
]

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes)]
})
export class AppRoutingModule {}
