import { Component, Input, ViewChild, ViewContainerRef, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { ModalConfig } from './modal-config'

@Component({
  selector: `eld-modal`,
  templateUrl: `./modal.component.html`,
  styleUrls: [`./modal.component.scss`]
})
export class ModalComponent implements OnInit {

  //input to receive the modal configuration (global buttons and actions)
  @Input() public modalConfig: ModalConfig

  //input to hold the component(form,label...) to be injected in our modal
  @Input() public cmpt: any

  //other data sent with the component: response results, input placeholders...
  @Input() public dynamicComponentData: any

  //Reference to the container where dynamic component will be injected
  @ViewChild(`modalBodyContainer`, { read: ViewContainerRef, static: true })
  public modalBodyContainer: ViewContainerRef

  private _cmptRef

  constructor (private activeModal: NgbActiveModal) { }

  public ngOnInit (): void {

    //Clear the container where dynamic component will be injected
    this.modalBodyContainer.clear()

    //Create the dynamic component and inject it into the container
    this._cmptRef =
        this.modalBodyContainer.createComponent(this.cmpt)

    //Assign the dynamicComponentData properties to the dynamic component instance
    Object.assign(this._cmptRef.instance, this.dynamicComponentData)
  }

  /**
  * Closes the modal with saving changes.
  * If the shouldClose function returns false, the modal won't close.
  * If the onClose function is defined,
  * it will be executed before closing the modal.
  * @returns A Promise that resolves when the modal is closed or
  * rejected if the modal should not be closed.
  */
  public async close (): Promise<void> {
    const shouldClose = this.modalConfig.shouldClose
      ? await this.modalConfig.shouldClose() : true

    const output = this._cmptRef.instance.output

    //do not close if the modal configured not to be closed
    //Or the form passed byinjectedCmpt is invalid
    if (!shouldClose || output?.invalid) {
      return
    }

    //excecute the onclose with injecting any results
    //coming from injected form
    const onCloseResult = this.modalConfig.onClose
      && await this.modalConfig
        .onClose(output?.valid && output.value)
    this.closeModalWithResult(onCloseResult)
  }

  /**
  * Dismisses the modal without saving changes.
  * If the shouldDismiss function returns false, the modal won't dismiss.
  * If the onDismiss function is defined,
  * it will be executed before dismissing the modal.
  * @returns A Promise that resolves when the modal is dismissed or
  * rejected if the modal should not be dismissed.
  */
  public async dismiss (): Promise<void> {
    const shouldDismiss = this.modalConfig.shouldDismiss
      ? await this.modalConfig.shouldDismiss() : true

    if (shouldDismiss) {
      const onDismissResult = this.modalConfig.onDismiss
        ? await this.modalConfig.onDismiss() : undefined
      this.dismissModalWithResult(onDismissResult)
    }
  }

  /**
  * Closes the modal with a given result.
  * @param result The result to pass when closing the modal.
  */
  private closeModalWithResult (result?: any): void {
    this.activeModal.close(result)
  }

  /**
  * Dismisses the modal with a given result.
  * @param result The result to pass when dismissing the modal.
  */
  private dismissModalWithResult (result?: any): void {
    this.activeModal.dismiss(result)
  }
}
