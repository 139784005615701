export class Response<T> {
  constructor (
    public message: string,
    public data: T
  ) { }

  public isValid (): boolean {
    return this.message === `OK`
  }

  public  get valid (): boolean {
    return this.message === `OK`
  }

  public get invalid (): boolean {
    return this.message !== `OK`
  }
}
