import { Injectable } from '@angular/core'
import { ILoginInfo } from '../models/auth/loginInfo.interface'

@Injectable({
  providedIn: `root`
})
export class StorageService {
  public getLoginInfo = (): ILoginInfo => this.get<ILoginInfo>(`loginInfo`)

  public setLoginInfo = (value: ILoginInfo): void =>
    this.set(`loginInfo`, value)

  public getToken = (): string => this.get<string>(`token`)

  public setToken = (value: string): void => this.set(`token`, value)

  public deleteToken = (): void => this.delete(`token`)

  private set<T> (key: string, value: T) {
    localStorage.setItem(key, JSON.stringify(value))
  }

  private get<T> (key: string): T | undefined {
    return JSON.parse(localStorage.getItem(key))
  }

  private delete (key: string): void {
    return localStorage.removeItem(key)
  }

  private clear (): void {
    return localStorage.clear()
  }
}
