import { Activity } from './activity.class'

export class Permission {
  public _id: string

  public label: string

  public app: string

  public active: boolean

  public description: string

  public activities: Array<Activity>

  public creationDate: Date

  public updateDate: Date

  constructor (obj: any) {
    if (typeof obj === `string`) {
      this._id = obj
      return
    }

    const properties = [`_id`, `label`, `app`, `active`, `description`]

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }

    if (obj.activities) {
      this.activities = obj.activities.map((a) => new Activity(a))
    }

    if (obj.creationDate) {
      this.creationDate = new Date(obj.creationDate)
    }

    if (obj.updateDate) {
      this.updateDate = new Date(obj.updateDate)
    }
  }

  public activitiesIds (): string[] {
    return this.activities.map(({ _id }) => _id)
  }

  public toString (): string {
    return this.label
  }

  public get exportFields (): { [key: string]: string[] } {
    return { permission: [`label`, `app`, `active`,`activities`, `description`] }
  }
}
