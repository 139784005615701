import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { BehaviorSubject, Observable, iif, of, throwError } from 'rxjs'
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators'
import { Response } from 'src/app/shared/models/http/response.class'
import { ILoginInfo } from 'src/app/shared/models/auth/loginInfo.interface'
import { User } from 'src/app/shared/models/users/user.class'
import { StorageService } from 'src/app/shared/services/storage.service'
import { environment } from 'src/environments/environment'
import { Authn } from 'src/app/shared/models/security/authn.class'

@Injectable({
  providedIn: `root`
})
export class AuthService {
  public loggedIn = new BehaviorSubject<boolean>(false)

  public loggedInUser = new BehaviorSubject<User>(undefined)

  public permissions: Observable<string[]> = this.loggedInUser.pipe(
    filter(user => !!user?.authn),
    map(({ authn }) => authn.roles),
    map(roles =>
      roles.reduce(
        (acc, { permissions }) => [
          ...acc,
          ...permissions
            .filter(({ active, app }) => active && app === `app-dashboard`)
            .map(({ label }) => label)
        ],
        []
      )
    )
  )

  constructor (
    private http: HttpClient,
    private storageService: StorageService,
    private translateService: TranslateService
  ) {
    const token = storageService.getToken()
    if (token) {
      this.loggedIn.next(true)
    }
  }

  public get isLoggedIn$ () {
    return this.loggedIn.asObservable()
  }

  private validResponse (observable: Observable<any>) {
    return observable.pipe(
      tap(({ message }) => {
        if (message !== `OK`) {
          throw new Error(message)
        }
      }),
      map(response => response.data),
      catchError(error => {
        throw new Error(error.error?.message || error.message)
      })
    )
  }

  public login (loginInfo: ILoginInfo): Observable<string> {
    return this.validResponse(
      this.http.put<Response<any>>(
        `${environment.baseUrl}${environment.archContext.authn}`,
        loginInfo
      )
    ).pipe(
      tap(token => {
        this.storageService.setToken(token)
        this.loggedIn.next(true)
      })
    )
  }

  public getMe (): Observable<User> {
    return this.validResponse(
      this.http.get<Response<any>>(`${environment.baseUrl}/account/user`)
    ).pipe(
      map(data => data),
      tap(user => {
        this.translateService.use(user.locale)
      }),
      tap(user => {
        this.loggedInUser.next(user)
      })
    )
  }

  public updatePassword (oldPassword: string, newPassword: string)
  : Observable<any> {
    return this.http
      .patch<any>(`${environment.baseUrl}${environment.archContext.authn}/password`, {
        oldPassword: oldPassword,
        newPassword: newPassword
      })
  }

  public resetPassword (email: string, token: string, newPassword: string)
  : Observable<any> {
    return this.http
      .post<any>(`${environment.baseUrl}${environment.archContext.authn}/password`,
        { email, token, newPassword })
      .pipe(
        tap(response => {
          const msg: string = response.message
          if (!msg.startsWith(`ERROR`) && msg !== `OK`) {
            throw new Error(`ERROR.${msg}`)
          }
        })
      )
  }

  public requestChangePwdCode (email: string): Observable<any> {
    return this.http
      .get<any>(`${environment.baseUrl}${environment.archContext.authn}/password/${email}`)
      .pipe(
        tap(response => {
          const msg: string = response.message
          //forced to do this because of inconsistent backend
          if (msg === `WRONG_EMAIL`) {
            throw new Error(`ERROR.WRONG_EMAIL_ADDRESS`)
          }
          if (!msg.startsWith(`ERROR`) && msg !== `OK`) {
            throw new Error(`ERROR.${msg}`)
          }
        })
      )
  }

  public getAuthn (authnId: string):Observable<Response<Authn | null>> {
    return this.http.get<Response<Authn | null>>(`${environment.baseUrl}/authn/${authnId}`)
      .pipe(
        switchMap(({ message, data }) => iif(() => message === `OK`,
          of({ message, data }),throwError(() => new Error(message)))),
        map(({ message, data }) => (data)
          ? new Response<Authn>(message, new Authn(data))
          : new Response<null>(message, null)))
  }

  public logout (): void {
    this.storageService.deleteToken()
    this.loggedInUser.next(undefined)
    this.loggedIn.next(false)
  }
}
