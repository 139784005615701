import { Component } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { AuthService } from '../../login/services/auth.service'
import { MenuItem } from '../../shared/models/generic/menuItem.interface'
import { menuItems } from './menuItems'

@Component({
  selector: `eld-sidebar`,
  templateUrl: `./sidebar.component.html`,
  styleUrls: [`./sidebar.component.scss`]
})
export class SidebarComponent {

  protected authorisedMenu$: Observable<MenuItem[]> =
    this.authService.permissions.pipe(
      map((permissions) =>
        this.getAuthorisedMenu(permissions, menuItems))
    )

  constructor (public authService: AuthService) {}

  private getAuthorisedMenu (
    permissions: string[],
    menuItems: MenuItem[]
  ): MenuItem[] {
    //verify if the grouped menuItems are authorised
    return menuItems.reduce((acc, menuItem) => {
      if (menuItem.subMenu && menuItem.subMenu.length) {
        const validatedSubItems = menuItem.subMenu.filter((item) =>
          this.isAuthorisedItem(permissions, item)
        )

        if (validatedSubItems.length) {
          acc = [...acc, { ...menuItem, subMenu: validatedSubItems }]
        }
      }
      //verify if the main menuItem having no nested menuItems is authorised
      if (menuItem.route && this.isAuthorisedItem(permissions, menuItem)) {
        acc = [...acc, menuItem]
      }
      return acc
    }, [])
  }

  private isAuthorisedItem (permissions: string[], item: MenuItem): boolean {
    return permissions.includes(item.label)
  }
}
