import { Component, OnInit } from '@angular/core'
import { AuthService } from './login/services/auth.service'
import { lastValueFrom } from 'rxjs'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: `eld-root`,
  styleUrls: [`./app.component.scss`],
  templateUrl: `./app.component.html`
})
export class AppComponent implements OnInit {
  constructor (
    public authService: AuthService,
    private router: Router,
    public _translate: TranslateService
  ) {}

  //this is needed to get user info
  //when reloading or initialising
  public async ngOnInit () {
    if (this.authService.loggedIn.value) {
      await lastValueFrom(this.authService.getMe())
    } else {
      this.router.navigateByUrl(`/login`)
      this.authService.logout()
    }
  }
}
