export const commonEnvironment = {
  //accounts and authns
  archContext: {
    authn: `/authns`,
    activity: `/activities`,
    role: `/roles`,
    permission: `/permissions`,
    banner: `/banner`
  },
  //echarge
  plugContext: {
    plug: `/plug`
  },
  echargeContext: {
    echarge: `/echarge`,
    transaction: `/echarge/transaction`,
    connection: `/echarge/connection`,
    park: `/echarge/park`,
    zone: `/echarge/zone`
  },
  //station
  stationContext: {
    station: `/station`
  },
  //accounts
  accountContext: {
    account: `/account`,
    customer: `/account/customer`,
    device: `/account/device`,
    paymentprovider: `/account/paymentprovider`,
    admin: `/account/admin`,
    car: `/account/car`,
    user: `/account/user`
  },
  //product
  productContext: {
    vendor: `/product/vendor`
  },
  //order
  orderContext: {
    order: `/order`,
    pos: `/order/pos`
  }
}
