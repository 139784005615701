<!-- The modal template -->
<div #modal>
  <div class="modal-header">
    <!-- The modal title -->
    <h4 class="modal-title fw-bolder">
      <i class="fa fa-{{ modalConfig?.icon }} p-1"></i>
      {{ modalConfig.title | translate }}
    </h4>
    <span class="dismiss-icon p-2 cursor-pointer" (click)="dismiss()">
      <i class="fa fa-xmark fa-xl"></i>
    </span>
  </div>
  <!-- injected body which is a component-->
  <ng-template
    class="modal-body container-fluid"
    #modalBodyContainer
  ></ng-template>
  <div
    class="modal-footer"
    *ngIf="!modalConfig.hideCloseButton && !modalConfig.hideDismissButton"
  >
    <!-- Dismiss button -->
    <div class="row justify-content-end">
      <button
        type="button"
        class="col btn btn-pill btn-secondary btn-size m-1"
        (click)="dismiss()"
        *ngIf="
          modalConfig.hideDismissButton === undefined ||
          !modalConfig.hideDismissButton()
        "
        [disabled]="
          modalConfig.disableDismissButton !== undefined &&
          modalConfig.disableDismissButton()
        "
      >
        {{ modalConfig.dismissButtonLabel | translate }}
      </button>
      <!-- Close button -->
      <button
        type="button"
        class="col btn btn-pill btn-{{
          modalConfig.closeButtonType ? modalConfig.closeButtonType : 'primary'
        }} btn-size  m-1"
        (click)="close()"
        *ngIf="
          modalConfig.hideCloseButton === undefined ||
          !modalConfig.hideCloseButton()
        "
        [disabled]="
          modalConfig.disableCloseButton !== undefined &&
          modalConfig.disableCloseButton()
        "
      >
        {{ modalConfig.closeButtonLabel | translate }}
      </button>
    </div>
  </div>
</div>
