import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http'
import { Observable, tap } from 'rxjs'
import { Router } from '@angular/router'

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor (private router: Router) {}

  //Intercept method required by HttpInterceptor interface
  public intercept (
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    //Check if a user is logged in by retrieving the token from local storage
    const currentUser = JSON.parse(localStorage.getItem(`token`))

    //If a token is available, add it to the request headers
    if (currentUser) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser}`
        }
      })
    }

    //Continue with the modified request and handle any errors
    return next.handle(request).pipe( tap(
      (error: any) => {
        //Check if the error is an HTTP error response
        if (error instanceof HttpErrorResponse) {
          //If the error is not a 401 (Unauthorized) status, do nothing
          if (error.status !== 401) {
            return
          }
          //Redirect to the error page for unauthorized access
          this.router.navigate([`/error-page/page-not-authorised`])
        }
      }))
  }
}
