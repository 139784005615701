import { Confirmation } from './confirmation'
import { Password } from './password'
import { Role } from './role.class'

export class Authn {
  public _id: string

  public password: Password

  public confirmation: Confirmation

  public roles: Role[]

  public email: string

  public creationDate: Date

  constructor (obj: any) {
    if (typeof obj === `string`) {
      this._id = obj
      return
    }

    const properties = [`_id`, `password`, `confirmation`, `email`]

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }

    if (obj.roles) {
      this.roles = obj.roles.map((role) => new Role(role))
    }

    if (obj.creationDate) {
      this.creationDate = new Date(obj.creationDate)
    }
  }
}
