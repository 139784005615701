<div class="d-flex flex-column justify-content-between">
  <nav id="sidebar" class="sidebar">
    <div class="sidebar-content js-simplebar d-flex">
      <a
        class="d-flex flex-column align-items-center mt-4 mb-6"
        href="index.html"
      >
        <img src="assets/e-mobile.png" class="w-25 mx-auto d-block" />
      </a>

      <ul class="sidebar-nav mb-3">
        <ng-container *ngFor="let menuItem of authorisedMenu$ | async">
          <li class="sidebar-item">
            <ng-container
              *ngIf="menuItem.subMenu && menuItem.subMenu.length > 0"
            >
              <a
                [attr.data-bs-target]="'#' + menuItem.label.replace(' ', '')"
                data-bs-toggle="collapse"
                class="sidebar-link collapsed"
              >
                <i
                  class="align-middle me-2 fas fa-lg fa-fw {{ menuItem.icon }}"
                ></i>
                <span class="align-middle">{{
                  'MENU.' + menuItem.label | translate
                }}</span>
              </a>
              <ul
                [id]="menuItem.label.replace(' ', '')"
                class="sidebar-dropdown list-unstyled collapse"
              >
                <li
                  *ngFor="let subMenuItem of menuItem.subMenu"
                  class="sidebar-item"
                >
                  <a
                    class="sidebar-link d-none d-md-block"
                    [routerLink]="subMenuItem.route"
                  >
                    {{ 'MENU.' + subMenuItem.label | translate }}
                  </a>
                  <a
                    class="sidebar-link d-block d-md-none"
                    [href]="subMenuItem.route"
                  >
                    {{ 'MENU.' + subMenuItem.label | translate }}
                  </a>
                </li>
              </ul>
            </ng-container>
            <ng-container *ngIf="!menuItem.subMenu || !menuItem.subMenu.length">
              <a
                class="sidebar-link d-none d-md-block"
                [routerLink]="menuItem.route"
              >
                <i
                  class="align-middle me-2 fas fa-lg fa-fw {{ menuItem.icon }}"
                ></i>
                {{ 'MENU.' + menuItem.label | translate }}
              </a>
              <a
                class="sidebar-link d-block d-md-none"
                [routerLink]="menuItem.route"
              >
                <i
                  class="align-middle me-2 fas fa-lg fa-fw {{ menuItem.icon }}"
                ></i>
                {{ 'MENU.' + menuItem.label | translate }}
              </a>
            </ng-container>
          </li>
        </ng-container>
      </ul>
    </div>
  </nav>
</div>
