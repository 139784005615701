import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { AuthService } from 'src/app/login/services/auth.service'

@Injectable({
  providedIn: `root`
})
export class AuthGuard {
  constructor (public authService: AuthService, public router: Router) {}

  public canActivate (): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.isLoggedIn$.pipe(
      tap((authenticated) => {
        if (!authenticated) {
          this.router.navigate([`login`])
        }
      })
    )
  }
}
