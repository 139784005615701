<div class="wrapper">
  <eld-sidebar
    [ngStyle]="{ display: (authService.isLoggedIn$ | async) ? 'flex' : 'none' }"
  >
  </eld-sidebar>
  <div class="main" style="max-height: 100vh">
    <eld-navbar
      [ngStyle]="{
        display: (authService.isLoggedIn$ | async) ? 'block' : 'none'
      }"
    >
    </eld-navbar>
    <div class="content p-0">
      <router-outlet></router-outlet>
    </div>
    <eld-toasts aria-live="polite" aria-atomic="true"></eld-toasts>
  </div>
</div>
