<div class="container-fluid p-0">
  <div class="row d-flex justify-content-center my-md-4 my-lg-4">
    <div class="col-12 col-md-6 col-lg-4">
      <div class="card">
        <div class="card-header d-flex justify-content-center">
          <span class="fw-bolder card-title mb-0">
            {{ 'LABEL.PAGE.PAGE_NOT_FOUND' | translate }}
          </span>
        </div>
        <img
          src="assets/ev.jpg"
          class="card-img-top w-25 mx-auto d-block"
          alt="logo"
        />

        <div class="card-body">
          <div class="card-body">
            <p class="card-text">
              {{ 'LABEL.PAGE.NOT_FOUND_TEXT' | translate }}
            </p>
            <div class="mt-4">
              <a [routerLink]="'/dashboard/home'" class="card-link">{{
                'BUTTON.HOME' | translate
              }}</a>
              <a [routerLink]="'/help-center/contact-us'" class="card-link">{{
                'BUTTON.HELP' | translate
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
