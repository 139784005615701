export class Activity {
  public _id: string

  public url: string

  public method: string

  public urlRegex: string

  public creationDate: Date

  public updateDate: Date

  constructor (obj: any) {
    if (typeof obj === `string`) {
      this._id = obj
      return
    }

    const properties = [`_id`, `method`, `urlRegex`]

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }

    if (obj.url && obj.url.trim().length) {
      this.url = obj.url
    }
    if (obj.creationDate) {
      this.creationDate = new Date(obj.creationDate)
    }

    if (obj.updateDate) {
      this.updateDate = new Date(obj.updateDate)
    }
  }

  public toString (): string {
    return `${this.method} ${this.url}`
  }

  public get exportFields (): { [key: string]: string[] } {
    return { activity: [`url`, `method`, `urlRegex`] }
  }
}
