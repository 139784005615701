<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [autohide]="toast.dismissible || false"
  [delay]="6000"
  [class]="'bg-' + toast.type.toLowerCase() + ' text-dark'"
  (hiddden)="toastService.remove(toast)"
>
  <ng-template ngbToastHeader>
    <div class="me-auto">
      <i [class]="toast.icon" aria-hidden="true"></i>
      <label class="mx-1">{{ toast.header || toast.type | translate }}</label>
    </div>
  </ng-template>
  {{ toast.body | translate }}
</ngb-toast>
