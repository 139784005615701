import { Injectable } from '@angular/core'

/**
 * @author rsb
 *
 * The toasts' service manages a collection of toasts.
 * It also provides a public method to push a new toast
 * to the same collection, remove it and clear toasts.
 */
export enum ToastType {
  SUCCESS = `SUCCESS`,
  WARNING = `WARNING`,
  DANGER = `DANGER`,
  INFO = `INFO`
}

export interface ToastInfo {
  header: string
  body: string
  type: ToastType
  icon: string
  dismissible?: boolean
}

@Injectable({
  providedIn: `root`
})
export class ToastService {
  public toasts: ToastInfo[] = []

  public show (
    header: string,
    body: string,
    type: ToastType,
    dismissible?: boolean
  ) {
    let icon = ``
    switch (type) {
      case ToastType.INFO:
        icon = `fa fa-info-circle`
        break
      case ToastType.SUCCESS:
        icon = `fa fa-check-circle`
        break
      case ToastType.WARNING:
        icon = `fa fa-exclamation-circle`
        break
      case ToastType.DANGER:
        icon = `fa fa-times-circle`
        break
      default:
        icon = `fa fa-info-circle`
        break
    }
    this.toasts.push({
      header,
      body,
      dismissible,
      type,
      icon
    })
  }

  public remove (toast: ToastInfo) {
    this.toasts = this.toasts.filter((t) => t !== toast)
  }

  public clear () {
    this.toasts.splice(0, this.toasts.length)
  }
}
