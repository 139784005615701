import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { TranslateModule } from '@ngx-translate/core'
import { ToastsComponent } from './toasts.component'

@NgModule({
  declarations: [ToastsComponent],
  exports: [ToastsComponent],
  imports: [CommonModule, NgbModule, TranslateModule, FontAwesomeModule]
})
export class ToastsModule {}
