<div class="container-fluid p-0">
  <nav class="d-flex row m-0 navbar p-3 navbar-expand navbar-light navbar-bg">
    <div class="col">
      <div class="row align-items-center">
        <div class="col-auto p-0">
          <a class="sidebar-toggle">
            <i class="hamburger align-self-center"></i>
          </a>
        </div>
        <div
          class="col-2 col-logo d-none d-sm-inline-block"
          routerLink="index.html"
        >
          &nbsp;
        </div>
      </div>
    </div>
    <div class="col-auto">
      <div class="navbar-collapse collapse row">
        <div class="navbar-nav navbar-align col">
          <div class="row">
            <div class="nav-item dropdown col-auto p-0">
              <a
                class="nav-flag dropdown-toggle"
                href="#"
                id="languageDropdown"
                data-bs-toggle="dropdown"
              >
                <img
                  [src]="'assets/flags/' + currentLang + '.png'"
                  [alt]="'LOCALE.' + currentLang?.toUpperCase() | translate"
                />
              </a>
              <div
                class="dropdown-menu dropdown-menu-end"
                aria-labelledby="languageDropdown"
              >
                <ng-container
                  *ngFor="let locale of translateService.getLangs()"
                >
                  <a class="dropdown-item" (click)="setTranslateLocale(locale)">
                    <img
                      [src]="'assets/flags/' + locale + '.png'"
                      [alt]="'LOCALE.' + locale.toUpperCase() | translate"
                      width="20"
                      class="align-middle me-1"
                    />
                    <span class="align-middle">{{
                      'LOCALE.' + locale.toUpperCase() | translate
                    }}</span>
                  </a>
                </ng-container>
              </div>
            </div>

            <div class="nav-item dropdown col-auto p-0">
              <a
                class="nav-icon dropdown-toggle d-inline-block d-sm-none"
                href="#"
                data-bs-toggle="dropdown"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-settings align-middle"
                >
                  <circle cx="12" cy="12" r="3"></circle>
                  <path
                    d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"
                  ></path>
                </svg>
              </a>
              <a
                class="nav-link dropdown-toggle d-none d-sm-inline-block"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src="assets/avatars/avatar.png"
                  class="avatar img-fluid rounded-circle me-1"
                  alt="LoggedIn User"
                />
                <span
                  class="text-dark navbar-label text-truncate"
                  *ngIf="authService.loggedInUser | async as user"
                >
                  {{ user.firstname.charAt(0).toUpperCase() }}.
                  {{ user.lastname }}
                </span>
              </a>
              <div class="dropdown-menu dropdown-menu-end">
                <!-- ngStyle is needed here as data-feather not loading with ngif -->
                <a
                  class="dropdown-item"
                  [routerLink]="'/account-management/profile'"
                  [ngStyle]="{
                    display: isAuthorized ? 'flex' : 'none'
                  }"
                  ><i class="align-middle me-1" data-feather="user"></i>
                  {{ 'BUTTON.PROFILE' | translate }}
                </a>
                <a
                  class="dropdown-item"
                  [routerLink]="'/help-center/contact-us'"
                  ><i class="align-middle me-1" data-feather="help-circle"></i>
                  {{ 'BUTTON.HELP' | translate }}
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" (click)="logout()"
                  ><i class="align-middle me-1" data-feather="log-out"></i>
                  {{ 'BUTTON.SIGN_OUT' | translate }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</div>
