import { Component } from '@angular/core'
import { ToastService } from '../../services/toast.service'

@Component({
  selector: `eld-toasts`,
  styleUrls: [`./toasts.component.scss`],
  templateUrl: `./toasts.component.html`
})
export class ToastsComponent {
  constructor (public toastService: ToastService) {}
}
