import { Permission } from './../security/permission.class'

export class Role {
  public _id: string

  public name: string

  public permissions: Array<Permission>

  public creationDate: Date

  public updateDate: Date

  constructor (obj: any) {
    if (typeof obj === `string`) {
      this._id = obj
      return
    }

    const properties = [`_id`, `name`]

    for (const property in obj) {
      if (properties.includes(property)) {
        this[property] = obj[property]
      }
    }

    if (obj.permissions) {
      this.permissions = obj.permissions.map(
        (permission) => new Permission(permission)
      )
    }

    if (obj.creationDate) {
      this.creationDate = new Date(obj.creationDate)
    }

    if (obj.updateDate) {
      this.updateDate = new Date(obj.updateDate)
    }
  }

  public get permissionsId (): string[] {
    return this.permissions.map((permission) =>
      typeof permission === `object` ? permission._id : permission
    )
  }

  public toString (): string {
    return this.name
  }

  public get exportFields (): { [key: string]: string[] } {
    return { role: [`name`, `permissions`, `creationDate`, `updateDate`] }
  }
}
