import { AbstractControl } from '@angular/forms'

export function isAuthorizedAction (
  permissions: string[],
  action: string
): boolean {
  return permissions.includes(action)
}

export function formatDateToISO (date: string | null): string {
  if (!date) {
    return ``
  }

  //Ensure that the parts array has at least year, month, and day
  if (date.split(`-`).length < 3) {
    throw new Error(`Invalid date format.`)
  }

  const isoDateString = new Date(date).toISOString()

  return isoDateString
}

export const noWhitespacesOnly = (control: AbstractControl) =>
  `${control.value || ``}`.trim().length ? null : { required: true }

export const namePattern =
  /^[-'a-zA-Z \u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F]+$/
